import React from 'react'
import * as AppDefinitions from '../../../App.d'
import {
	AccountXeroOrg,
	Calendar as CalendarData,
	Employee,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveType,
} from '../../../../../back-end/utilities/apiDefinitions'
import { Row, Col, FormLabel, ButtonGroup, Dropdown, DropdownButton, Form, Table, Button } from 'react-bootstrap'
import { TimePeriodPicker } from '../../oversight/timePeriodPicker'
import * as I from '../../../utilities/me'
import moment from 'moment'
import { X } from 'react-feather'
import { PieChart } from 'react-minimal-pie-chart'
import { getDataForPieChart } from './overview'
interface EmployeePerformanceOverviewProps {
	allLeaveApplications: LeaveApplication[] | []
	allXeroOrgLeaveApplications: LeaveApplication[] | []
	originalBalanceData: EmployeeLeaveBalance[]
	originalXeroOrgBalanceData: EmployeeLeaveBalance[]
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	xeroOrgCalendars: CalendarData[] | null
	currentCalendar: CalendarData
}

const defaultDateRange = {
	startDate: moment().subtract(30, 'days'),
	endDate: moment(),
}

interface EmployeeLeaveStats {
	name: string
	count: number
	duration: number
	balance: number
	afterWeekends: number
	calendarCount: number
	companyCount: number
	calendarDuration: number
	companyDuration: number
	calendarBalance: number
	companyBalance: number
	calendarAfterWeekends: number
	companyAfterWeekends: number
	unitType: string
}

const EmployeePerformanceOverview = (props: EmployeePerformanceOverviewProps) => {
	const [period, updatePeriod] = React.useState<{
		startDate: moment.Moment | null
		endDate: moment.Moment | null
	}>(defaultDateRange)
	const [employee, updateEmployee] = React.useState<Employee | undefined>(undefined)
	const [calendarEmployees, updateCalendarEmployees] = React.useState<Employee[] | []>([])
	const [employeeLeaveStats, updateEmployeeLeaveStats] = React.useState<{ [leaveTypeID: string]: EmployeeLeaveStats }>({})
	const [calculated, updateCalculated] = React.useState<boolean>(false)
	const [results, updateResults] = React.useState<number | null>(null)

	React.useEffect(
		() => {
			const fetchData = async () => {
				updateCalendarEmployees(props.currentCalendar.employees!.sort((a, b) => (a.firstName! > b.firstName! ? 1 : -1)))
			}

			if (props.currentCalendar.calendarID !== '') {
				fetchData()
			}
		},
		// eslint-disable-next-line
		[props.appState.authState, props.currentCalendar.calendarID, props.currentOrg, props.currentCalendar, period]
	)

	const leavesInPeriod = React.useCallback(
		(type: 'employee' | 'leave' | 'xeroorg', id: string, period: { startDate: moment.MomentInput; endDate: moment.MomentInput }) => {
			if (type === 'xeroorg') {
				const leavesInPeriod = props.allXeroOrgLeaveApplications.filter(
					(leave) =>
						moment(leave.startDate).isBetween(period.startDate, period.endDate) || moment(leave.endDate).isBetween(period.startDate, period.endDate)
				)
				return leavesInPeriod.filter((leave) => leave.xeroOrgLeaveTypeID === id)
			} else {
				const leavesInPeriod = props.allLeaveApplications.filter(
					(leave) =>
						moment(leave.startDate).isBetween(period.startDate, period.endDate) || moment(leave.endDate).isBetween(period.startDate, period.endDate)
				)
				return type === 'employee'
					? leavesInPeriod.filter((leave) => leave.employeeID === id)
					: leavesInPeriod.filter((leave) => leave.xeroOrgLeaveTypeID === id)
			}
		},
		[props.allXeroOrgLeaveApplications, props.allLeaveApplications]
	)

	const leavesFollowingWeekend = React.useCallback((leaves: LeaveApplication[]) => {
		return leaves.filter((lapp) => moment(lapp.startDate!).day() === 1).length || 0 // 1 represents Monday
	}, [])

	const oneLeaveAppDuration = React.useCallback(
		(totalLeave: number, leaveApp: LeaveApplication) =>
			(totalLeave += leaveApp.leavePeriod
				? leaveApp.leavePeriod.reduce((totalUnits, leavePeriod) => (totalUnits += Number(leavePeriod.numberOfUnits) || 0), 0)
				: 0),
		[]
	)

	const leavesTotalDuration = React.useCallback(
		(leaveTypeTotal: number, leaves: LeaveApplication[]) =>
			(leaveTypeTotal += leaves.reduce((totalUnits, leave) => (totalUnits += Number(oneLeaveAppDuration(0, leave)) || 0), 0)),
		[oneLeaveAppDuration]
	)

	const totalBalanceValue = React.useCallback(
		(totalValue: number, balances: EmployeeLeaveBalance[]) => (totalValue += balances.reduce((totalUnits, bl) => (totalUnits += Number(bl.value) || 0), 0)),
		[]
	)

	const calculateEmployeeStats = React.useCallback(
		(employeeID: string, period: any) => {
			const employeeLeaves = leavesInPeriod('employee', employeeID, period)
			const calUniqueEmployeeID = props.currentCalendar.employees!.filter((e) => e.isActive).map((e) => e.employeeID!)
			const allXeroEmployeeID = props.xeroOrgCalendars!.reduce(
				(employeeIDs: string[], currentCal) => employeeIDs.concat(currentCal.employees!.map((e) => e.employeeID!)),
				[]
			)
			const employeeStats = props.currentCalendar.leaveTypes!.reduce((result, currentValue: LeaveType) => {
				const currentEmployeeLeaveTypeLeaves = employeeLeaves.filter((l) => l.xeroOrgLeaveTypeID === currentValue.xeroOrgLeaveTypeID!)
				const currentXeroOrgLeaveTypeLeaves = leavesInPeriod('xeroorg', currentValue.xeroOrgLeaveTypeID!, period)
				const currentCalLeaveTypeLeaves = leavesInPeriod('leave', currentValue.xeroOrgLeaveTypeID!, period)
				const currentEmployeeLeaveTypeBalance = props.originalBalanceData.filter(
					(bl) => bl.employeeID === employeeID && bl.xeroOrgLeaveTypeID === currentValue.xeroOrgLeaveTypeID!
				)
				const currentCalLeaveTypeBalance = props.originalBalanceData.filter(
					(bl) => calUniqueEmployeeID.includes(bl.employeeID!) && bl.xeroOrgLeaveTypeID === currentValue.xeroOrgLeaveTypeID!
				)
				const currentOrgLeaveTypeBalance = props.originalBalanceData.filter(
					(bl) => allXeroEmployeeID.includes(bl.employeeID!) && bl.xeroOrgLeaveTypeID === currentValue.xeroOrgLeaveTypeID!
				)
				if (currentEmployeeLeaveTypeLeaves.length > 0) {
					result[currentValue.xeroOrgLeaveTypeID!] = {
						name: currentValue.name!,
						count: currentEmployeeLeaveTypeLeaves.length || 0,
						duration: leavesTotalDuration(0, currentEmployeeLeaveTypeLeaves),
						balance: currentEmployeeLeaveTypeBalance.length > 0 ? currentEmployeeLeaveTypeBalance[0].value! : 0,
						afterWeekends: leavesFollowingWeekend(currentEmployeeLeaveTypeLeaves),
						calendarCount: currentCalLeaveTypeLeaves.length || 0,
						companyCount: currentXeroOrgLeaveTypeLeaves.length || 0,
						calendarDuration: leavesTotalDuration(0, currentCalLeaveTypeLeaves),
						companyDuration: leavesTotalDuration(0, currentXeroOrgLeaveTypeLeaves),
						calendarBalance: Math.round(totalBalanceValue(0, currentCalLeaveTypeBalance)),
						companyBalance: Math.round(totalBalanceValue(0, currentOrgLeaveTypeBalance)),
						calendarAfterWeekends: leavesFollowingWeekend(currentCalLeaveTypeLeaves),
						companyAfterWeekends: leavesFollowingWeekend(currentXeroOrgLeaveTypeLeaves),
						unitType: currentValue.unitType!,
					}
				}
				return result
			}, {} as { [leaveTypeID: string]: EmployeeLeaveStats })

			updateEmployeeLeaveStats(employeeStats)
			return employeeStats
		},
		[
			leavesInPeriod,
			leavesTotalDuration,
			leavesFollowingWeekend,
			totalBalanceValue,
			props.currentCalendar.leaveTypes,
			props.originalBalanceData,
			props.xeroOrgCalendars,
			props.currentCalendar.employees,
			props.xeroOrgCalendars,
		]
	)

	const organiseDataForPieChart = React.useCallback((type: 'number' | 'duration') => {
		if (employeeLeaveStats && Object.keys(employeeLeaveStats).length !== 0) {
			const data = Object.values(employeeLeaveStats).reduce((result, current) => {
				result[current.name] = type === 'number' ? current.count : current.duration
				return result
			}, {} as { [name: string]: number })
			return data
		}
		return {}
	}, [])

	const presentDetails = () => {
		// Calculate values
		const employeeStats = calculateEmployeeStats(employee ? employee.employeeID! : '', period)
		updateCalculated(true)
		updateResults(Object.keys(employeeStats!).length)
	}

	return (
		<Row className="calendar-alerts-card ">
			<Col>
				<Row style={{ paddingLeft: '20px' }}>
					<Col xs={'auto'} sm={2} md={2} lg={'auto'} className={'emails-label'}>
						Employee Performance Overview
					</Col>
					<TimePeriodPicker
						dates={period}
						id={'period'}
						noPeriod={true}
						handleDatesChange={(dates) => {
							updatePeriod(dates)
							updateEmployee(employee ? employee : undefined)
						}}
					/>
				</Row>

				<div
					style={{
						marginTop: '5px',
						paddingLeft: '20px',
						backgroundColor: 'white',
						paddingTop: '20px',
					}}
				>
					<Row className="leavecal-wrap-style">
						<Col sm="2" style={{ textAlign: 'right', marginTop: '8px' }}>
							<FormLabel>Employee:</FormLabel>
						</Col>
						<Col sm="auto">
							<DropdownButton
								as={ButtonGroup}
								id={'dropdown'}
								variant={'primary'}
								title={
									(employee as unknown as Employee)
										? employee!.alias
											? employee!.alias
											: `${employee!.firstName} ${employee!.lastName}`
										: 'Select employee'
								}
								onSelect={(eventKey) => {
									if (eventKey && calendarEmployees) {
										updateEmployee(calendarEmployees!.find((e: Employee) => e.employeeID === eventKey) || undefined)
									}
								}}
								alignRight
								className={!I.have('admin', props.appState.permission[props.appState.context]) ? 'no-hover' : ''}
							>
								{calendarEmployees
									? calendarEmployees.map((emp) => (
											<Dropdown.Item
												key={emp.employeeID}
												eventKey={emp.employeeID}
												active={(employee as unknown as Employee) ? emp.employeeID === employee!.employeeID : false}
											>
												{emp!.alias ? emp!.alias : `${emp!.firstName} ${emp!.lastName}`}
											</Dropdown.Item>
									  ))
									: 'No employees'}
							</DropdownButton>
							{employee ? (
								<Button
									className="circle"
									style={{ marginLeft: '5px' }}
									variant={'dark'}
									onClick={(e) => {
										updateEmployee(undefined)
										updateEmployeeLeaveStats({})
									}}
								>
									<X />
								</Button>
							) : null}
						</Col>
						<Col style={{ paddingTop: '3px' }}>
							<Button
								type="button"
								disabled={!(period.startDate && period.endDate)}
								style={{ padding: '3px 20px' }}
								onClick={() => presentDetails()}
							>
								Calculate
							</Button>
						</Col>
					</Row>
					{period.startDate && period.endDate ? (
						<>
							{employee && calculated ? (
								<div style={{ overflow: 'scroll', marginRight: '15px' }}>
									<Form.Group style={{ padding: '30px' }}>
										<Table borderless responsive className="no-wrap">
											<thead>
												<tr className="stripe-row-body">
													<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
														Leave Type
													</th>
													<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
														Number
													</th>
													<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
														Duration
													</th>
													<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
														Balance
													</th>
													<th className="header-sticky-top" style={{ fontWeight: 'bold' }}>
														After Weekends
													</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{employeeLeaveStats && results && results > 0 ? (
													Object.values(employeeLeaveStats).map((lt, index) => (
														<tr key={`${lt.name}-${index}`}>
															<td>{lt.name}</td>
															<td>{`Employee: ${lt.count}\nCalendar: ${lt.calendarCount}\n Org: ${lt.companyCount}`}</td>
															<td>{`Employee: ${lt.duration} ${lt.unitType}\nCalendar: ${lt.calendarDuration} ${lt.unitType}\n Org: ${lt.companyDuration} ${lt.unitType}`}</td>
															<td>{`Employee: ${lt.balance} ${lt.unitType}\nCalendar: ${lt.calendarBalance} ${lt.unitType}\n Org: ${lt.companyBalance} ${lt.unitType}`}</td>
															<td>{`Employee: ${lt.afterWeekends}\nCalendar: ${lt.calendarAfterWeekends}\n Org: ${lt.companyAfterWeekends}`}</td>
														</tr>
													))
												) : (
													<p style={{ margin: '5px' }}>No leave taken during this period.</p>
												)}
											</tbody>
										</Table>
									</Form.Group>
									{results && results > 0 ? (
										<Row
											style={{
												marginTop: '-5px',
												paddingLeft: '20px',
												backgroundColor: 'white',
												paddingTop: '20px',
											}}
										>
											<Col style={{ textAlign: 'center' }}>
												<PieChart
													data={getDataForPieChart(organiseDataForPieChart('number'), 0)}
													style={{ height: '230px' }}
													label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
													labelStyle={{
														fontSize: '5px',
													}}
													labelPosition={60}
													animate
												/>
												<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Number Of Applications</FormLabel>
											</Col>
											<Col style={{ textAlign: 'center' }}>
												<PieChart
													data={getDataForPieChart(organiseDataForPieChart('duration'), 1)}
													style={{ height: '230px' }}
													label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
													labelStyle={{
														fontSize: '5px',
													}}
													labelPosition={60}
													animate
												/>
												<FormLabel style={{ paddingTop: '20px', fontWeight: 'bold' }}>Duration</FormLabel>
											</Col>
										</Row>
									) : null}
								</div>
							) : (
								<Row
									style={{
										marginTop: '5px',
										paddingLeft: '20px',
										backgroundColor: 'white',
										paddingTop: '20px',
										paddingBottom: '50px',
									}}
								>
									<p style={{ fontWeight: 'lighter' }}>
										{employee ? 'Press Calculate to display a summary for this period.' : 'Select an employee for the performance summary.'}
									</p>
								</Row>
							)}
						</>
					) : (
						<Row
							style={{
								marginTop: '5px',
								paddingLeft: '20px',
								backgroundColor: 'white',
								paddingTop: '20px',
								paddingBottom: '50px',
							}}
						>
							<p style={{ fontWeight: 'lighter' }}>Select a date range in order to start displaying an employee performance summary.</p>
						</Row>
					)}
				</div>
			</Col>
		</Row>
	)
}

export default EmployeePerformanceOverview
