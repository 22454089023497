import * as React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Globe, Settings } from 'react-feather'
import { DateTime } from 'luxon'

// components
import BodyFrame, { FrameType } from '../../components/subsync/frame'
import ModalList from '../../components/ui/modalList/modalList'
import SubSyncOnboardModal from '../../components/subsync/onboardModal'
import * as Messages from '../../components/ui/messages/messages'

// definitions
import {
	AccountStripeOrg,
	AccountXeroOrg,
	StripeInvoice,
	StripeOrgAccountXeroOrg,
	StripeProduct,
	StripeTaxRate,
	XeroAccount,
	XeroItem,
	XeroTaxRate,
	XeroContact,
	StripeMappingType,
	XeroTrackingCategory,
	StripeProductXeroMapping,
} from '../../../../back-end/utilities/apiDefinitions'
import { ModalState } from '../leavecal/leavecal.d'
import { DetailedStripeOrgAccountXeroOrg } from '../../components/subsync/settings/settings'

// utilities
import * as Request from '../../utilities/request'
import * as I from '../../utilities/me'
import { AppContext } from '../../App'
import { useLocation } from 'react-router-dom'

const getFrameFromHash = (hash: string): FrameType => {
	return ['overview', 'settings'].includes(hash) ? (hash as FrameType) : 'overview'
}

interface HistoryView {
	events: StripeInvoice[]
}

interface AccountStripeOrgWithData extends AccountStripeOrg {
	products: StripeProduct[]
	taxRates: StripeTaxRate[]
}

interface AccountXeroOrgWithData extends AccountXeroOrg {
	accounts: XeroAccount[]
	items: XeroItem[]
	taxRates: XeroTaxRate[]
	contacts: XeroContact[]
	trackingCategories: XeroTrackingCategory[]
}

interface OrgSettings {
	stripeOrgs: AccountStripeOrgWithData[]
	xeroOrgs: AccountXeroOrgWithData[]
	orgMappings: DetailedStripeOrgAccountXeroOrg[]
	stripeMappingTypes: StripeMappingType[]
}

interface RetryBody {
	stripeInvoiceID: string
	stripeOrgID?: string
	tenantID?: string
	xeroOrgID?: string
	userAccountXeroOrgAuthID?: string
}

const initialModalState: ModalState = {
	modal: '',
	props: {},
	size: 'sm',
}

const SubSync = () => {
	const { appState } = React.useContext(AppContext)
	const location = useLocation()

	const [messages, updateMessages] = Messages.useMessageReducer([])

	const [currentFrame, updateCurrentFrame] = React.useState<FrameType>(getFrameFromHash(location.hash.substring(1)))
	const [pageStatus, updatePageStatus] = React.useState<string>('Loading')
	const [shownModal, updateShownModal] = React.useState<ModalState>(initialModalState)

	const [historyView, updateHistoryView] = React.useState<HistoryView>({
		events: [],
	})
	const [orgSettings, updateOrgSettings] = React.useState<OrgSettings>({
		stripeOrgs: [],
		xeroOrgs: [],
		orgMappings: [],
		stripeMappingTypes: [],
	})

	const refreshData = React.useCallback(async () => {
		const fetchData = async () => {
			updatePageStatus('Loading')
			const eventCutoff = DateTime.local().minus({ months: 3 }).startOf('month').toUTC().toJSON()
			let invoiceData: StripeInvoice[] = []
			const [
				stripeOrgs,
				xeroOrgs,
				orgMappings,
				products,
				accounts,
				items,
				stripeTaxRates,
				xeroTaxRates,
				contacts,
				trackingCategories,
				stripeMappingTypes,
				xeroUrl,
			] = await Promise.all(
				[
					Request.get('accountstripeorg?where=StripeOrgIsConnected==1', appState.authState),
					Request.get('organisation?where=AccountXeroOrgIsSubSync==1', appState.authState),
					Request.get('stripeorgaccountxeroorg', appState.authState),
					Request.get('stripeproduct', appState.authState),
					Request.get('xeroaccount', appState.authState),
					Request.get('xeroitem', appState.authState),
					Request.get('stripetaxrate', appState.authState),
					Request.get('xerotaxrate', appState.authState),
					Request.get('xeroContact', appState.authState),
					Request.get('xerotrackingcategory', appState.authState),
					Request.get('stripemappingtype', appState.authState),
				]
					.concat(I.have('admin', appState.permission[appState.context]) ? [Request.get('xero/url/subsync', appState.authState)] : [])
					.concat([
						Request.getPagedData(
							`stripeinvoice?where=SubSyncEventTs>=${eventCutoff}&`,
							4000,
							(data) => (invoiceData = invoiceData.concat(data.stripeInvoices)),
							appState.authState
						),
					])
			)

			// update onboarding
			if (
				(xeroOrgs.data.accountXeroOrgs.length === 0 || stripeOrgs.data.accountStripeOrgs.length === 0) &&
				I.am(appState.permission[appState.context]) === 'Admin'
			) {
				updateShownModal({
					modal: 'Connect',
					props: {
						xeroConsentUrl: xeroUrl.data.url,
						xeroIsConnected: xeroOrgs.data.accountXeroOrgs.length > 0,
						stripeIsConnected: stripeOrgs.data.accountStripeOrgs.length > 0,
					},
					size: 'md',
				})
			}

			updateHistoryView({
				...historyView,
				events: combinePagedInvoiceData(invoiceData),
			})
			updateOrgSettings({
				stripeOrgs: groupStripeOrgsWithData(stripeOrgs.data.accountStripeOrgs, products.data.stripeProducts, stripeTaxRates.data.stripeTaxRates),
				xeroOrgs: groupXeroOrgsWithData(
					xeroOrgs.data.accountXeroOrgs,
					accounts.data.xeroAccounts,
					items.data.xeroItems,
					xeroTaxRates.data.xeroTaxRates,
					contacts.data.xeroContacts,
					trackingCategories.data.xeroTrackingCategories
				),
				orgMappings: formatOrgMappings(orgMappings.data.stripeOrgAccountXeroOrgs, products.data.stripeProducts),
				stripeMappingTypes: stripeMappingTypes.data.stripeMappingTypes,
			})
			updatePageStatus('Finished')
		}

		if (appState.authState.isLoggedIn && appState.permission[appState.context]) {
			fetchData()
		}
	}, [appState.authState, appState.permission[appState.context]]) // eslint-disable-line

	React.useEffect(() => {
		if (appState.authState.isLoggedIn) {
			refreshData()
		}
	}, [appState.authState, refreshData])

	const refreshSettingsData = async () => {
		updatePageStatus('Loading')
		// First we trigger getting the info from xero for all the Orgs
		await Promise.all(
			orgSettings.xeroOrgs.map(async (org) => {
				const body = {
					tenantID: org.xeroOrg!.tenantID,
					xeroOrgID: org.xeroOrg!.xeroOrgID,
					version: org.xeroOrg!.version,
					userAccountXeroOrgAuthID: org.userAccountXeroOrgAuth!.id,
				}
				await Request.put('stripexerodataupdate', body, appState.authState)
			})
		)
		// then we get everything saved in our DB after the refresh
		const [xeroOrgs, accounts, items, xeroTaxRates, stripeOrgs, products, stripeTaxRates, contacts, trackingCategories] = await Promise.all([
			Request.get('organisation?where=AccountXeroOrgIsSubSync==1', appState.authState),
			Request.get('xeroaccount', appState.authState),
			Request.get('xeroitem', appState.authState),
			Request.get('xerotaxrate', appState.authState),
			Request.get('accountstripeorg', appState.authState),
			Request.get('stripeproduct', appState.authState),
			Request.get('stripetaxrate', appState.authState),
			Request.get('xerocontact', appState.authState),
			Request.get('xerotrackingcategory', appState.authState),
		])
		updateOrgSettings({
			stripeOrgs: groupStripeOrgsWithData(stripeOrgs.data.accountStripeOrgs, products.data.stripeProducts, stripeTaxRates.data.stripeTaxRates),
			xeroOrgs: groupXeroOrgsWithData(
				xeroOrgs.data.accountXeroOrgs,
				accounts.data.xeroAccounts,
				items.data.xeroItems,
				xeroTaxRates.data.xeroTaxRates,
				contacts.data.xeroContacts,
				trackingCategories.data.xeroTrackingCategories
			),
			orgMappings: formatOrgMappings(orgSettings.orgMappings, products.data.stripeProducts),
			stripeMappingTypes: orgSettings.stripeMappingTypes,
		})
		updatePageStatus('Finished')
	}

	const updateFrameAndHash = (frame: FrameType) => {
		window.location.hash = frame
		updateCurrentFrame(frame)
	}

	const refreshInvoiceData = async () => {
		updatePageStatus('Loading')
		const invoices = await Request.get(`stripeinvoice`, appState.authState)

		updateHistoryView({
			...historyView,
			events: invoices.data.stripeInvoices,
		})
		updatePageStatus('Finished')
	}

	const retryFailedTransaction = async (body: RetryBody) => {
		const org = orgSettings.xeroOrgs.find((o) => o.xeroOrg!.xeroOrgID === body.xeroOrgID)
		if (org && org.userAccountXeroOrgAuth && org.xeroOrg) {
			await Request.put(
				`striperetrytransaction`,
				{ ...body, userAccountXeroOrgAuthID: org.userAccountXeroOrgAuth.id, tenantID: org.xeroOrg.tenantID },
				appState.authState
			)
		}
		updateMessages({
			type: 'add',
			data: {
				severity: 'success',
				message: 'Retrying transaction - this might take a minute',
				timeout: 3000,
				dismissible: true,
			},
		})
	}

	return (
		<div className="leavecal-root">
			<div className="leavecal-container">
				<Row className="leavecal-blue-bar"></Row>
			</div>

			<ModalList
				handleClose={() => {
					updateShownModal(initialModalState)
				}}
				show={shownModal.modal}
				data={shownModal.props}
				size={shownModal.size}
				modals={[
					{
						id: 'Connect',
						body: SubSyncOnboardModal,
					},
				]}
			/>

			<Row className="leavecal-body">
				<div className="leavecal-left-menu">
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-overview`}>Overview</Tooltip>}>
								<div>
									<Globe
										color={currentFrame === 'overview' ? 'black' : 'grey'}
										className={'clickable'}
										onClick={() => updateFrameAndHash('overview')}
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{I.have('admin', appState.permission[appState.context]) ? (
						<Row>
							<OverlayTrigger placement="auto" overlay={<Tooltip id={`tooltip-settings`}>Settings</Tooltip>}>
								<div>
									<Settings
										color={currentFrame === 'settings' ? 'black' : 'grey'}
										className={'clickable'}
										onClick={() => updateFrameAndHash('settings')}
									/>
								</div>
							</OverlayTrigger>
						</Row>
					) : null}
					{/* this is to fill up the rest of the page */}
					<div style={{ flexGrow: 1 }}></div>
				</div>
				<Col>
					<BodyFrame
						appState={appState}
						pageStatus={pageStatus}
						currentFrame={currentFrame}
						historyView={historyView}
						refreshSettingsData={refreshSettingsData}
						refreshOverviewData={refreshInvoiceData}
						updateHistoryView={updateHistoryView}
						orgSettings={orgSettings}
						updateOrgSettings={updateOrgSettings}
						retryFailedTransaction={retryFailedTransaction}
					/>
				</Col>
			</Row>

			<Messages.Messages messages={messages} updateMessage={updateMessages} />
		</div>
	)
}

const combinePagedInvoiceData = (invoices: StripeInvoice[]) =>
	invoices.reduce((final, curr) => {
		const invoiceIndex = final.findIndex((prev) => prev.stripeInvoiceID === curr.stripeInvoiceID)

		if (invoiceIndex >= 0) {
			final[invoiceIndex].events = final[invoiceIndex].events!.concat(curr.events!)
		} else {
			final.push(curr)
		}

		return final
	}, [] as StripeInvoice[])

const groupStripeOrgsWithData = (orgs: AccountStripeOrg[], products: StripeProduct[], taxRates: StripeTaxRate[]) =>
	orgs.map((org) => ({
		...org,
		products: products.filter((product) => product.stripeOrgID === org.stripeOrg!.stripeOrgID),
		taxRates: taxRates.filter((taxRate) => taxRate.stripeOrgID === org.stripeOrg!.stripeOrgID),
	}))

const groupXeroOrgsWithData = (
	orgs: AccountXeroOrg[],
	accounts: XeroAccount[],
	items: XeroItem[],
	taxRates: XeroTaxRate[],
	contacts: XeroContact[],
	trackingCategories: XeroTrackingCategory[]
) =>
	orgs.map((org) => ({
		...org,
		accounts: accounts.filter((account) => account.xeroOrgID === org.xeroOrg!.xeroOrgID),
		items: items.filter((item) => item.xeroOrgID === org.xeroOrg!.xeroOrgID),
		taxRates: taxRates.filter((taxRate) => taxRate.xeroOrgID === org.xeroOrg!.xeroOrgID),
		contacts: contacts.filter((contact) => contact.xeroOrgID === org.xeroOrg!.xeroOrgID),
		trackingCategories: trackingCategories.filter((category) => category.xeroOrgID === org.xeroOrg!.xeroOrgID),
	}))

const formatOrgMappings = (orgMappings: StripeOrgAccountXeroOrg[], stripeProducts: StripeProduct[]) =>
	orgMappings.map((orgMapping) => ({
		...orgMapping,
		stripeProductXeroMappings: orgMapping
			.stripeProductXeroMappings!.map((spxm) =>
				spxm.xeroAccountID === null && spxm.xeroItemID === null ? { ...spxm, xeroAccountID: 'N/A', xeroItemID: 'N/A' } : spxm
			)
			.concat(
				// add default not in use mapping for any archived products that don't already have mappings
				stripeProducts
					.filter(
						(stripeProduct: StripeProduct) =>
							stripeProduct.isActive === false &&
							!orgMapping.stripeProductXeroMappings!.some((spxm) => spxm.stripeProductID === stripeProduct.stripeProductID)
					)
					.map((stripeProduct: StripeProduct) => defaultNotInUseProductMapping(stripeProduct))
			),
		stripeTaxRateXeroTaxRates: orgMapping.stripeTaxRateXeroTaxRates!.map((strxtr) =>
			strxtr.xeroTaxRateID === null ? { ...strxtr, xeroTaxRateID: 'N/A' } : strxtr
		),
	}))

const defaultNotInUseProductMapping = (product: StripeProduct): StripeProductXeroMapping & { edited: boolean } => ({
	stripeProductID: product.stripeProductID,
	xeroAccountID: 'N/A',
	xeroItemID: 'N/A',
	edited: true,
})

export type { OrgSettings, HistoryView, RetryBody, AccountStripeOrgWithData, AccountXeroOrgWithData }
export default SubSync
