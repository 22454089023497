import React from 'react'
import * as AppDefinitions from '../../../App.d'
import {
	AccountXeroOrg,
	Calendar as CalendarData,
	Employee,
	EmployeeLeaveBalance,
	LeaveApplication,
	LeaveType,
} from '../../../../../back-end/utilities/apiDefinitions'

import { Row, Col, FormLabel, FormGroup, Button } from 'react-bootstrap'
import { TimePeriodPicker } from '../../oversight/timePeriodPicker'
import { PieChart } from 'react-minimal-pie-chart'
import Plot from 'react-plotly.js'
import moment from 'moment'

import { getDataForPieChart, getDataForBarChart, getCalendarsWithBalances } from './overview'

interface LeaveOverviewComponentProps {
	allLeaveApplications: LeaveApplication[] | []
	originalBalanceData: EmployeeLeaveBalance[]
	appState: AppDefinitions.AppState
	currentOrg: AccountXeroOrg
	currentCalendar: CalendarData
}

const initialDateRange = {
	startDate: moment().subtract(30, 'days'),
	endDate: moment(),
}

const LeaveOverviewComponent = (props: LeaveOverviewComponentProps) => {
	const [period, updatePeriod] = React.useState<{
		startDate: moment.Moment | null
		endDate: moment.Moment | null
	}>(initialDateRange)
	const [calculated, updateCalculated] = React.useState<boolean>(true)
	const [results, updateResults] = React.useState<number | null>(null)

	// this function should be refactored
	const daysOff = React.useCallback(
		(start: Date, end: Date) => {
			const employeesGroup = props.currentCalendar
				.employees!.filter((e) => e.isActive)
				.reduce((result, currentValue: Employee) => {
					result[currentValue.employeeID!] = {
						name: `${currentValue.alias ? currentValue.alias : `${currentValue.firstName} ${currentValue.lastName}`}`,
						daysOff: 0,
					}
					return result
				}, {} as { [employeeID: string]: { name: string; daysOff: number } })

			const filteredBalances = getCalendarsWithBalances(
				props.currentCalendar,
				props.allLeaveApplications,
				props.originalBalanceData,
				props.currentOrg!.xeroOrg!.timezone!,
				start,
				end
			)

			filteredBalances.employees
				.map((emp) => emp.leaveBalance)
				.flat()
				.forEach((lb) => {
					if (employeesGroup[lb.employee!.employeeID!]) {
						employeesGroup[lb.employee!.employeeID!] = {
							...employeesGroup[lb.employee!.employeeID!],
							daysOff: employeesGroup[lb.employee!.employeeID!].daysOff! + lb.upcoming,
						}
					}
				})

			return employeesGroup
		},
		[props.currentCalendar, props.allLeaveApplications, props.originalBalanceData, props.currentOrg]
	)

	const totalDaysOff = React.useMemo(
		() => (daysOff: { [employeeID: string]: { name: string; daysOff: number } }) => {
			let totalDays = 0
			Object.keys(daysOff).forEach((emp) => (totalDays = totalDays + daysOff[emp].daysOff))
			return totalDays
		},
		[daysOff]
	)

	const employeeWithMostDaysOff = React.useMemo(
		() => (daysOff: { [employeeID: string]: { name: string; daysOff: number } }) => {
			let maxDays = {
				name: '',
				daysOff: 0,
			}

			Object.keys(daysOff).forEach((emp) => {
				if (daysOff[emp].daysOff > maxDays.daysOff) {
					maxDays = daysOff[emp]
				}
			})

			return maxDays
		},
		[daysOff]
	)

	// this function should be refactored
	const employeeWithMostRequests = React.useMemo(
		() => (start: Date, end: Date) => {
			const employeesGroup = props.currentCalendar
				.employees!.filter((e) => e.isActive)
				.reduce((result, currentValue: Employee) => {
					result[currentValue.employeeID!] = {
						name: `${currentValue.alias ? currentValue.alias : `${currentValue.firstName} ${currentValue.lastName}`}`,
						requests: 0,
					}
					return result
				}, {} as { [employeeID: string]: { name: string; requests: number } })

			const filteredBalances = getCalendarsWithBalances(
				props.currentCalendar,
				props.allLeaveApplications,
				props.originalBalanceData,
				props.currentOrg!.xeroOrg!.timezone!,
				start,
				end
			)
			filteredBalances.employees
				.map((emp) => emp.leaveBalance)
				.flat()
				.forEach((lb) => {
					if (employeesGroup[lb.employee!.employeeID!]) {
						employeesGroup[lb.employee!.employeeID!] = {
							...employeesGroup[lb.employee!.employeeID!],
							requests: employeesGroup[lb.employee!.employeeID!].requests + lb.corespondingLeaves.length,
						}
					}
				})

			let maxRequestss = {
				name: '',
				requests: 0,
			}

			Object.keys(employeesGroup).forEach((emp) => {
				if (employeesGroup[emp].requests > maxRequestss.requests) {
					maxRequestss = employeesGroup[emp]
				}
			})

			return maxRequestss
		},
		[props.currentCalendar, props.allLeaveApplications, props.originalBalanceData, props.currentOrg]
	)

	const groupLeaveBetweenPeriod = React.useMemo(
		() => (start: Date, end: Date) => {
			const filteredCals = getCalendarsWithBalances(
				props.currentCalendar,
				props.allLeaveApplications,
				props.originalBalanceData,
				props.currentOrg!.xeroOrg!.timezone!,
				start,
				end
			)
			const allLeaves = filteredCals.employees
				.map((emp) => emp.leaveBalance)
				.flat()
				.map((lb) => lb.corespondingLeaves)
				.flat()
			const nrDays = Number((end.getTime() + 1000 * 3600 * 24 - start.getTime()) / (1000 * 3600 * 24))
			const periodBreakDown = nrDays <= 7 ? 'days' : nrDays <= 30 ? 'weeks' : nrDays <= 365 ? 'months' : 'years'
			let i = 0
			if (periodBreakDown === 'days') {
				const daysWithLeaves: { [day: string]: number } = {}
				while (i < nrDays) {
					const currentDay = moment(start)
					currentDay.add(i, 'days')
					daysWithLeaves[moment(currentDay).format('dddd')] = 0
					allLeaves.forEach((l) => {
						if (moment(l.startDate!).day() === currentDay.day() && currentDay.day() <= moment(l.endDate!).day()) {
							daysWithLeaves[moment(currentDay).format('dddd')] = daysWithLeaves[moment(currentDay).format('dddd')] + 1
						}
					})
					i = i + 1
				}

				return daysWithLeaves
			} else if (periodBreakDown === 'weeks') {
				const weeksWithLeaves: { [week: string]: number } = {}
				while (i <= nrDays) {
					const currentDay = moment(start)
					currentDay.add(i, 'days')
					const weekName = `Week ${i / 7 + 1}`
					weeksWithLeaves[weekName] = 0
					allLeaves.forEach((l) => {
						if (moment(l.startDate!).valueOf() <= currentDay.valueOf() && moment(l.endDate!).valueOf() <= currentDay.valueOf()) {
							weeksWithLeaves[weekName] = weeksWithLeaves[weekName] + 1
						}
					})
					i = i + 7
				}

				return weeksWithLeaves
			} else if (periodBreakDown === 'months') {
				const monthsWithLeaves: { [month: string]: number } = {}
				while (i <= nrDays) {
					const currentDay = moment(start)
					currentDay.add(i, 'days')
					monthsWithLeaves[moment(currentDay).format('MMMM')] = 0
					allLeaves.forEach((l) => {
						if (moment(l.startDate!).month() === currentDay.month() && currentDay.month() <= moment(l.endDate!).month()) {
							monthsWithLeaves[moment(currentDay).format('MMMM')] = monthsWithLeaves[moment(currentDay).format('MMMM')] + 1
						}
					})
					i = i + 28
				}
				return monthsWithLeaves
			} else {
				//years
				const yearsWithLeaves: { [year: string]: number } = {}
				while (i <= nrDays) {
					const currentDay = moment(start)
					currentDay.add(i, 'days')
					yearsWithLeaves[currentDay.format('YYYY')] = 0
					allLeaves.forEach((l) => {
						if (moment(l.startDate!).year() === currentDay.year() && currentDay.year() <= moment(l.endDate!).year()) {
							yearsWithLeaves[currentDay.format('YYYY')] = yearsWithLeaves[currentDay.format('YYYY')] + 1
						}
					})

					i = i + 365
				}

				return yearsWithLeaves
			}
		},
		[props.currentCalendar, props.allLeaveApplications, props.originalBalanceData, props.currentOrg]
	)

	const getLeaveTypesTaken = React.useMemo(
		() => (start: Date, end: Date) => {
			const leaveTypes = props.currentCalendar
				.leaveTypes!.sort((a, b) => a.name!.localeCompare(b.name!))
				.reduce((result, currentValue: LeaveType) => {
					result[currentValue.xeroOrgLeaveTypeID!] = {
						name: currentValue.name!,
						amount: 0,
					}
					return result
				}, {} as { [key: string]: { name: string; amount: number } }) //key is xeroOrgLeaveTypeID

			const filteredBalances = getCalendarsWithBalances(
				props.currentCalendar,
				props.allLeaveApplications,
				props.originalBalanceData,
				props.currentOrg!.xeroOrg!.timezone!,
				start,
				end
			)

			filteredBalances.employees
				.map((emp) => emp.leaveBalance)
				.flat()
				.map((lb) => lb.corespondingLeaves)
				.flat()
				.forEach((l) => {
					if (leaveTypes[l.xeroOrgLeaveTypeID!]) {
						leaveTypes[l.xeroOrgLeaveTypeID!].amount = leaveTypes[l.xeroOrgLeaveTypeID!].amount + 1 || 0
					}
				})

			return leaveTypes
		},
		[props.currentCalendar, props.allLeaveApplications, props.originalBalanceData, props.currentOrg]
	)

	const presentDetails = () => {
		const start = period.startDate!.toDate()
		const end = period.endDate!.toDate()

		// Calculate values
		const totalDays = totalDaysOff(daysOff(start, end))
		const mostDaysOff = employeeWithMostDaysOff(daysOff(start, end)).daysOff
		const mostRequests = employeeWithMostRequests(start, end).requests
		updateCalculated(true)
		updateResults(totalDays + mostDaysOff + mostRequests)
	}

	return (
		<Row className="calendar-alerts-card ">
			<Col>
				<Row style={{ paddingLeft: '20px' }}>
					<Col xs={'auto'} sm={2} md={2} lg={'auto'} className={'emails-label'}>
						Leave Overview
					</Col>
					<TimePeriodPicker
						dates={period}
						id={'period'}
						noPeriod={true}
						handleDatesChange={(dates) => {
							updateCalculated(false)
							updatePeriod(dates)
						}}
					/>
					<Col>
						<Button type="button" disabled={!(period.startDate && period.endDate)} style={{ padding: '3px 20px' }} onClick={() => presentDetails()}>
							Calculate
						</Button>
					</Col>
				</Row>
				{period.startDate && period.endDate && results && calculated ? (
					<div
						style={{
							marginTop: '5px',
							paddingLeft: '20px',
							backgroundColor: 'white',
							paddingTop: '20px',
						}}
					>
						{results > 0 ? (
							<>
								<FormGroup>
									<Row>
										<p>{`There were ${totalDaysOff(
											daysOff(period.startDate!.toDate(), period.endDate!.toDate())
										)} day(s) taken off in this period.`}</p>
									</Row>
									<Row>
										<p>{`${
											employeeWithMostDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())).name
										} took more leave than anyone with ${
											employeeWithMostDaysOff(daysOff(period.startDate!.toDate(), period.endDate!.toDate())).daysOff
										} day(s).`}</p>
									</Row>
									<Row>
										<p>{`${
											employeeWithMostRequests(period.startDate!.toDate(), period.endDate!.toDate()).name
										} had more requests approved than anyone with ${
											employeeWithMostRequests(period.startDate!.toDate(), period.endDate!.toDate()).requests
										} request(s).`}</p>
									</Row>
								</FormGroup>
								<FormGroup>
									<Row>
										<Col style={{ textAlign: 'center', marginTop: 'auto' }}>
											<PieChart
												data={getDataForPieChart(groupLeaveBetweenPeriod(period.startDate!.toDate(), period.endDate!.toDate()), 4)}
												style={{
													height: '300px',
													marginBottom: '100px',
												}}
												label={({ dataEntry }) => `${dataEntry.title!} (${dataEntry.value!})`}
												labelStyle={{
													fontSize: '5px',
												}}
												labelPosition={60}
												animate
											/>
											<FormLabel
												style={{
													paddingTop: '10px',
													fontWeight: 'bold',
												}}
											>{`Leave by ${
												Number(
													(period.endDate.toDate().getTime() + 1000 * 3600 * 24 - period.startDate.toDate().getTime()) /
														(1000 * 3600 * 24)
												) <= 7
													? 'Day'
													: Number(
															(period.endDate.toDate().getTime() + 1000 * 3600 * 24 - period.startDate.toDate().getTime()) /
																(1000 * 3600 * 24)
													  ) <= 30
													? 'Week'
													: Number(
															(period.endDate.toDate().getTime() + 1000 * 3600 * 24 - period.startDate.toDate().getTime()) /
																(1000 * 3600 * 24)
													  ) <= 365
													? 'Month'
													: 'Year'
											}`}</FormLabel>
										</Col>
										<Col style={{ textAlign: 'center' }}>
											<Plot
												style={{}}
												data={[
													getDataForBarChart(
														getLeaveTypesTaken(period.startDate!.toDate(), period.endDate!.toDate()),
														4,
														props.currentOrg.xeroOrg!.showLeaveInDays!,
														props.currentOrg.xeroOrg!.hoursInDay!
													),
												]}
												layout={{
													title: '',
													yaxis: {
														automargin: true,
														type: 'category',
														ticksuffix: '  ',
													},
													xaxis: {
														automargin: true,
														rangemode: 'nonnegative',
													},
													hovermode: 'closest',
													// width: 500,
													height: 500,
												}}
												config={{
													displayModeBar: false,
													responsive: true,
												}}
											/>
											<Col sm="3"></Col>
											<FormLabel
												style={{
													paddingTop: '10px',
													fontWeight: 'bold',
												}}
											>{`Leave by Type`}</FormLabel>
										</Col>
									</Row>
								</FormGroup>
							</>
						) : (
							<Row
								style={{
									marginTop: '5px',
									paddingLeft: '20px',
									backgroundColor: 'white',
									paddingTop: '20px',
									paddingBottom: '50px',
								}}
							>
								<p style={{ fontWeight: 'lighter' }}>There are no leave taken during this period.</p>
							</Row>
						)}
					</div>
				) : (
					<Row
						style={{
							marginTop: '5px',
							paddingLeft: '20px',
							backgroundColor: 'white',
							paddingTop: '20px',
							paddingBottom: '50px',
						}}
					>
						<p style={{ fontWeight: 'lighter' }}>
							{period.startDate && period.endDate
								? 'Press Calculate to display a summary for this period.'
								: 'Select a date range in order to display a summary for that period.'}
						</p>
					</Row>
				)}
			</Col>
		</Row>
	)
}

export default LeaveOverviewComponent
