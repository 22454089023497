import React from 'react'
import { Auth } from 'aws-amplify'
import { Image, Col, Row, Dropdown, Modal, Button, Form, Spinner } from 'react-bootstrap'
import { ChevronDown, Edit } from 'react-feather'

import { AppContext } from '../../App'
import * as AppDefinitions from '../../App.d'
import * as I from '../../utilities/me'
import * as Request from '../../utilities/request'
import * as ApiDefinitions from '../../../../back-end/utilities/apiDefinitions'

import { Dialog } from '../ui/dialog/dialog'
import { SubscriptionItem } from './subscriptionItem'
import { refreshSession } from '../../utilities/login'
import { CreateAccountDialog } from '../ui/dialog/newAccountDialog'
import { deepCopy } from '../../utilities/object'

import './auther.css'

interface UserButtonProps {
	userAttributes: AppDefinitions.CognitoUserAttributes
	permission: AppDefinitions.Permission
	accounts: ApiDefinitions.Account[]
	context: AppDefinitions.ProductContext
	selectedOrg?: ApiDefinitions.AccountXeroOrg
}

const findOutAccountCanUseKeypay = (props: UserButtonProps) => {
	if (props.accounts) {
		return props.accounts.some((acc) => acc.canUseKeypay)
	}
	return false
}

export const UserButton = (props: UserButtonProps) => {
	const { appState } = React.useContext(AppContext)
	const [showDialog, updateShowDialog] = React.useState<boolean>(false)
	const [currentAccountHasKeypay, updateCurrentAccountHasKeypay] = React.useState<boolean>(findOutAccountCanUseKeypay(props))
	const [showCreateAccountDialog, updateShowCreateAccountDialog] = React.useState<boolean>(false)

	React.useEffect(() => {
		updateCurrentAccountHasKeypay(findOutAccountCanUseKeypay(props))
	}, [props.accounts, props.selectedOrg])

	const signOut = () => {
		Auth.signOut()
			.then(() => {
				window.location.href = '/'
			})
			.catch((err: Error) => console.log(err))
	}

	const onAccountSwitch = async (accountID: string, accounts?: ApiDefinitions.Account[]) => {
		updateShowDialog(false)

		const selectedAccount = accounts ? accounts[0] : props.accounts.find((account) => account.accountID === accountID)
		await Auth.currentAuthenticatedUser()

		if (selectedAccount) {
			const user = await Auth.currentAuthenticatedUser()

			await Auth.updateUserAttributes(user, {
				'custom:currentAccountId': accountID,
				'custom:userAccountId': selectedAccount.userAccountID,
				'custom:role': JSON.stringify({
					os: selectedAccount.osPermissionID,
					lc: selectedAccount.lcPermissionID,
					tayg: selectedAccount.taygPermissionID,
					subsync: selectedAccount.subsyncPermissionID,
				}),
			})
			await refreshSession()
			window.location.href = '/'
			window.location.reload()
		}
	}

	return (
		<Dropdown>
			<Dropdown.Toggle className="no-caret" variant="secondary" id="userprofile">
				<Row>
					<Col xs="auto">
						<Image
							src={`https://ui-avatars.com/api/?name=${props.userAttributes.given_name}+${props.userAttributes.family_name}`}
							roundedCircle
							className="avatar"
						/>
					</Col>
					<Col xs="auto" className="dropdown-content">
						<Row>
							<Col>
								{props.userAttributes.given_name} {props.userAttributes.family_name}
							</Col>
						</Row>
						<Row>
							<Col>
								<small>{props.permission ? I.am(props.permission[props.context]) : ''}</small>
							</Col>
						</Row>
					</Col>
					<Col xs="auto" className="dropdown-end">
						<ChevronDown />
					</Col>
				</Row>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item href={'/'} disabled={!props.permission[props.context] || props.accounts.length === 0}>
					Home
				</Dropdown.Item>
				<Dropdown.Item href={`/users`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
					User Settings
				</Dropdown.Item>
				{props.context !== 'oversight' ? (
					<Dropdown.Item href={`/branding`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
						Custom Branding
					</Dropdown.Item>
				) : null}
				{props.context === 'oversight' ? (
					<>
						<Dropdown.Item href={`/tags`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
							Tag Settings
						</Dropdown.Item>
						<Dropdown.Item href={`/threshold`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
							Threshold Settings
						</Dropdown.Item>
					</>
				) : null}
				{props.context === 'leavecal' ? (
					<>
						{I.have('admin', props.permission[props.context]) ? (
							<>
								<SubscriptionItem
									selectedXeroOrg={props.selectedOrg?.xeroOrg}
									productCode="95a516a0-5aaf-4445-ba80-d20f3aaf855a"
									disable={!props.permission || props.accounts.length === 0}
								/>
								<Dropdown.Item href={`/google/integration`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
									Google Integration
								</Dropdown.Item>
								<Dropdown.Item href={`/ms/integration`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
									Outlook Integration
								</Dropdown.Item>
								<Dropdown.Item href={`/combinedcal`} disabled={!props.permission[props.context] || props.accounts.length === 0}>
									Combined Calendar
								</Dropdown.Item>
							</>
						) : null}
					</>
				) : null}
				{props.context === 'tayg' ? (
					<>
						{I.have('admin', props.permission[props.context]) ? (
							<>
								<SubscriptionItem
									selectedXeroOrg={props.selectedOrg?.xeroOrg}
									productCode="0b229c2a-4641-42a8-a66a-2dd0a37a0a33"
									disable={!props.permission || props.accounts.length === 0}
								/>
							</>
						) : null}
					</>
				) : null}
				{props.context === 'subsync' ? (
					<>
						{I.have('admin', props.permission[props.context]) ? (
							<>
								<SubscriptionItem
									selectedXeroOrg={props.selectedOrg?.xeroOrg}
									productCode="45607e76-1605-4f2a-85cf-5012327bf0ae"
									disable={!props.permission || props.accounts.length === 0}
								/>
							</>
						) : null}
					</>
				) : null}
				{I.have('admin', props.permission[props.context]) ? (
					<>
						<Dropdown.Item href={`/xero/connections`}>Xero Connections</Dropdown.Item>
						{/* <Dropdown.Item href={`/google/integration`}>Google Integration</Dropdown.Item> */}
					</>
				) : null}
				{I.have('admin', props.permission[props.context]) && props.context === 'subsync' ? (
					<>
						<Dropdown.Item href={`/stripe/connections`}>Stripe Connections</Dropdown.Item>
						{/* <Dropdown.Item href={`/google/integration`}>Google Integration</Dropdown.Item> */}
					</>
				) : null}
				{I.have('admin', props.permission[props.context]) && currentAccountHasKeypay ? (
					<>
						<Dropdown.Item href={`/keypay/connections`}>Keypay Connections</Dropdown.Item>
					</>
				) : null}
				<Dropdown.Item onClick={() => updateShowDialog(true)}>Switch Account</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item
					onClick={() => {
						window.open(`https://help.finlert.com/${props.context.replace('tayg', 'tax-as-you-go')}/`, '_blank', 'noopener,noreferrer')
					}}
				>
					Help Guides
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item onClick={signOut}>Log out</Dropdown.Item>

				<Dialog
					show={showDialog}
					dialogClassName="switch-account-dialog"
					handleClose={() => updateShowDialog(false)}
					titleText={'Log-in to a different account'}
					confirmText="Create New Account"
					showOnlyConfirm
					confirmFullWidth
					handleConfirm={() => {
						updateShowCreateAccountDialog(true)
						updateShowDialog(false)
					}}
				>
					<AccountSwitcherBody {...props} onAccountSwitch={onAccountSwitch} />
				</Dialog>

				<CreateAccountDialog
					show={showCreateAccountDialog}
					handleClose={() => updateShowCreateAccountDialog(false)}
					authState={appState.authState}
					switchToNewAccount={(accountID, accounts) => onAccountSwitch(accountID, accounts)}
				/>
			</Dropdown.Menu>
		</Dropdown>
	)
}

const AccountSwitcherBody = (props: UserButtonProps & { onAccountSwitch: (accountID: string, accounts?: ApiDefinitions.Account[]) => Promise<void> }) => {
	const { appState, updateAppState } = React.useContext(AppContext)
	const [accountEditIsSubmitting, setAccountEditIsSubmitting] = React.useState(false)
	const [accountsClone, setAccountsClone] = React.useState<ApiDefinitions.Account[]>(deepCopy(props.accounts))
	const [editingAccountID, setEditingAccountID] = React.useState<string | null>(null)

	React.useEffect(() => {
		setAccountsClone(deepCopy(props.accounts))
	}, [props.accounts])

	const onCancelEditAccountName = () => {
		setEditingAccountID(null)
		setAccountsClone(deepCopy(props.accounts))
	}

	const onUpdateAccountName = async (accountID: string, name?: string) => {
		if (!name) {
			onCancelEditAccountName()
			return
		}

		setAccountEditIsSubmitting(true)

		const updatedAccountRes = await Request.put(`account/name?where=AccountID==${accountID}`, { name }, appState.authState)

		if (updatedAccountRes.data.success) {
			const updatedAccounts = props.accounts.map((acc) => (acc.accountID === accountID ? { ...acc, name } : acc))
			updateAppState({ state: 'accounts', data: updatedAccounts })
			setAccountsClone(deepCopy(updatedAccounts))
			setEditingAccountID(null)
		}

		setAccountEditIsSubmitting(false)
	}

	const activeAccountID = props.selectedOrg?.account?.accountID || props.userAttributes['custom:currentAccountId']

	return (
		<Modal.Body>
			{accountsClone.map((acc, index) => {
				const isActive = acc.accountID === activeAccountID
				const isSelfEditing = editingAccountID === acc.accountID
				return (
					<Row key={acc.accountID} className={`align-items-center ${index > 0 ? 'mt-3' : ''}`}>
						<Col>
							<Form.Control
								name={`accName-${acc.accountID}`}
								value={acc.name}
								onChange={(e) =>
									setAccountsClone(accountsClone.map((a) => (a.accountID === acc.accountID ? { ...a, name: e.target.value } : a)))
								}
								readOnly={!isSelfEditing}
								plaintext={!isSelfEditing}
								disabled={!isSelfEditing}
							/>
						</Col>
						{editingAccountID ? (
							isSelfEditing ? (
								<Col sm="4">
									<Row>
										<Col sm="6" className="px-1">
											<Button
												variant="success"
												disabled={accountEditIsSubmitting || !acc.name}
												onClick={() => onUpdateAccountName(acc.accountID!, acc.name)}
												block
											>
												{accountEditIsSubmitting ? <Spinner animation="border" size="sm" /> : 'Save'}
											</Button>
										</Col>
										<Col sm="6" className="pl-1">
											<Button disabled={accountEditIsSubmitting} onClick={() => onCancelEditAccountName()} block>
												{accountEditIsSubmitting ? <Spinner animation="border" size="sm" /> : 'Cancel'}
											</Button>
										</Col>
									</Row>
								</Col>
							) : (
								<Col sm="4" />
							)
						) : (
							<>
								<Col sm="2" className="d-flex justify-content-start">
									<Edit size={20} onClick={() => setEditingAccountID(acc.accountID!)} className="clickable-icon" />
								</Col>
								<Col sm="3">
									<Button
										disabled={isActive}
										onClick={() => props.onAccountSwitch(acc.accountID!)}
										variant={isActive ? 'success' : undefined}
										block
									>
										{isActive ? 'Active' : 'Log-In'}
									</Button>
								</Col>
							</>
						)}
					</Row>
				)
			})}
		</Modal.Body>
	)
}
